import { defineStore } from 'pinia';
import { ElNotification } from 'element-plus/es';

export const useAppStore = defineStore('app', {
    state: () => ({
        apiLoading: false,
        flashError: '',
    }),
    getters: {},
    actions: {
        setFlashError(message: string) {
            this.flashError = message;
            if (this.flashError) {
                ElNotification({
                    title: 'Error',
                    message: this.flashError,
                    type: 'warning',
                });
            }

            setTimeout(() => {
                this.flashError = '';
            }, 5000);
        },
        setApiLoading(loading: boolean) {
            this.apiLoading = loading;
        },
    },
});
