<template>
    <div class="min-h-full relative" v-if="authenticated">
        <HeaderMain :user="user" @logout="logout"></HeaderMain>

        <header class="bg-white shadow">
            <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 class="text-3xl font-bold text-gray-900">{{ $route.meta.title || 'untitled' }}</h1>
            </div>
        </header>
        <main>
            <div class="max-w-full mx-auto py-6 sm:px-6 lg:px-8">
                <router-view></router-view>
            </div>
        </main>
    </div>
</template>

<script>
import { userStore } from '@/store/user';
import { useAppStore } from '@/store/app';
import { mapState } from 'pinia';
import HeaderMain from '@/components/header/HeaderMain';
import AuthService from './auth/AuthService';
import axios from 'axios';
import ApiService from '@/common/api';



const API_URL = 'http://localhost:8000';
const auth = new AuthService();

export default {
    name: 'app',
    components: {
        HeaderMain,
    },
    data() {


        this.handleAuthentication();
        this.authenticated = false;
        this.userStore = userStore();

        auth.authNotifier.on('authChange', (authState) => {
            this.authenticated = authState.authenticated;
            if (this.authenticated) {
                this.user = auth.getUserProfile();
                this.userStore.$patch({
                    data: this.user,
                    token: auth.getAuthToken(),
                });

                const api = new ApiService();
                api.get('auth/check/');
            }
        });

        return {
            user: {},
            authenticated: false,
            publicPath: process.env.BASE_URL,
            message: '',
            open: false,
        };
    },
    computed: {
        ...mapState(useAppStore, ['flashError']),
    },
    methods: {
        // this method calls the AuthService login() method
        login() {
            auth.login();
        },
        handleAuthentication() {
            auth.handleAuthentication();
        },
        logout() {
            this.user = {};
            return auth.logout();
        },
        privateMessage() {
            const url = `${API_URL}/api/grid/`;
            return axios
                .get(url, {
                    headers: { Authorization: `Bearer ${auth.getAuthToken()}` },
                })
                .then((response) => {
                    console.log(response.data);
                    this.message = response.data || '';
                });
        },
    },
};
</script>
