import { defineStore } from 'pinia';

export const userStore = defineStore('user', {
    state: () => ({
        data: {},
        token: '',
    }),
    getters: {},
    actions: {
        setUserData(data: object) {
            this.data = data;
        },
    },
});
