import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import piniaPersist from 'pinia-plugin-persist'


import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
})

const app = createApp(App);
const pinia = createPinia()
pinia.use(piniaPersist)

app.use(router);
app.use(pinia);
app.use(vuetify)
app.use(ElementPlus);



app.mount('#app');
