import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as qs from 'qs';
import { apiUrl } from '@/common/common';
import { userStore } from '@/store/user';
import { useAppStore } from '@/store/app';
import router from '../router';

export default class ApiService {
    private axios: AxiosInstance;
    private appStore: any;

    constructor() {
        const user = userStore();
        this.appStore = useAppStore();

        this.axios = axios.create({
            baseURL: apiUrl,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`,
            },
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: 'comma' });
            },
        } as AxiosRequestConfig);

        // Add a request interceptor
        this.axios.interceptors.request.use((config) => {
            // Do something before request is sent
            this.appStore.setApiLoading(true);
            if(config.params && config.params.limit && config.params.limit>100)
                this.appStore.setApiLoading(false);

            return config;
        }, (error) => {
            this.appStore.setApiLoading(false);
            return Promise.reject(error);
        });

        // Add a response interceptor
        this.axios.interceptors.response.use((response) => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            this.appStore.setApiLoading(false);
            return response;
        }, (error) => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            this.appStore.setApiLoading(false);
            return Promise.reject(error);
        });
    }

    async get(route: string, params?: AxiosRequestConfig | undefined) {
        this.appStore.setFlashError('');
        try {
            const response: AxiosResponse = await this.axios.get(route, { params });

            return response.data;
        } catch (error) {
            this.errorHandler(error);
        }
    }

    async post(route: string, data?: any) {
        this.appStore.setFlashError('');
        try {
            const response: AxiosResponse = await this.axios.post(route, data);

            return response.data;
        }
        catch (error) {
            this.errorHandler(error);
        }
    }

    errorHandler(error: any) {
        if (error.response.status === 401) {
            router.push('/unauthorized');
        } else if (error.response.status === 403) {
            this.appStore.setFlashError('Seems like you do not have the required permissions for this action.');
            console.log(error);
        } else {
            this.appStore.setFlashError(error.response.data.error);
            // alert(`[ERROR] ${error.response.data.error}\nsee console for full details.`);
            console.log(error);
        }
    }
}
